import {alertService} from "@/services/alert/alert.service";
import {authLoginFacilityService} from "@/services/auth/login/facility/login.service";

export default {
    data: () => {
        return {
            data: {
                auth_type: 'airport'
            },
            error: {},
            config: {}
        }
    },
    async mounted() {

    },
    methods: {
        async login$() {
            this.error = {}
            await alertService.loading();

            await authLoginFacilityService.login(this.data).then((response) => {
                this.$store.dispatch('roles');
                this.$router.push({name: 'facility-dashboard.index'}).then(() => alertService.close());
            }).catch((error) => {
                this.error = error;
                alertService.close();
            });

        },
    }

}
