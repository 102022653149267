import api from '../../../../globals/client';
import config from '../../../../config/app.config';

var promise;

export default class AuthLoginFacilityService {
    login(data) {
        promise =  api.client.post(config.end_point.auth.login, data);
        return promise;
    }
}

export const authLoginFacilityService = new AuthLoginFacilityService();
